<template>
  <div class="page" style="border:none!important;">
    <div class="content-container pt-0">
      <section class="dot-pattern m-0">
        <div class="content-band w1200 flex align-center">
          <div class="container w500">
            <h1 class="xxl font-white">
              Ohio <br />Developer P<span @click="handleEasterEggClick">o</span
              >rtal
            </h1>
            <p class="font-white">
              Creating a seamless user experience across state websites and
              applications, driven by the Ohio brand
            </p>
          </div>
          <img
            src="../assets/images/banner/dev-portal-heads.png"
            alt="developer portal illustration"
            style="max-width: 600px; margin-top: 77px; margin-left: 90px"
          />
        </div>
      </section>

      <!-- <section>
      <div class="content-band flex w1200 pt-30 ">
        <div class="container basis-30 spotlight"></div>
        <div
          class="container red basis-40 flex align-center justify-center plr-50"
        >
          <div class="w300">
            <h1 class="font-white">Lorem Ipsum <br />deleniti atque corrent</h1>
            <p class="font-white">
              The design philosophy, goals, and general principles of the Ohio
              Design System.
            </p>
          </div>
        </div>
      </div>
    </section> -->

      <section style="margin-top: 50px; margin-bottom: 150px">
        <div class="content-band flex-wrap even-cols gap-30 mt-0">
          <!-- CDN Refresh Tile -->
          <a
            class="tile white hover-dp-blue"
            @click="handleCDNCacheClick"
            @mouseover="isCDNHovered = true"
            @mouseleave="isCDNHovered = false"
          >
            <div class="tile_img">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 47.14 28.23"
                class="cdn-icon"
              >
                <path
                  d="M18.93,20.15l-13.51,0A6,6,0,0,1,6,8.21h.56a5.8,5.8,0,0,1,11.41,1.5v.06h1a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5h-2V9.71a4.8,4.8,0,0,0-9.52-.9l-.08.4H6a5,5,0,0,0-.48,9.92l.5,0H18.93a4.19,4.19,0,0,0,3.47-1.84.51.51,0,0,1,.7-.14.5.5,0,0,1,.13.7A5.18,5.18,0,0,1,18.93,20.15Z"
                />
                <path
                  d="M31.3,28.21H22.19l.88-5.15h7.35Zm-7.93-1h6.74l-.54-3.15H23.91Z"
                />
                <path
                  d="M34,28.21H19.49a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H34a.5.5,0,0,1,.5.5A.51.51,0,0,1,34,28.21Z"
                />
                <path
                  d="M45.92,28.23H36.78A1.21,1.21,0,0,1,35.57,27V9.76a1.22,1.22,0,0,1,1.21-1.22h9.14a1.22,1.22,0,0,1,1.22,1.22V27A1.22,1.22,0,0,1,45.92,28.23ZM36.78,9.54a.21.21,0,0,0-.21.22V27a.21.21,0,0,0,.21.21h9.14a.21.21,0,0,0,.22-.21V9.76a.22.22,0,0,0-.22-.22Z"
                />
                <path
                  class="cls-1"
                  d="M46.64,12.78H36.07a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H46.64a.5.5,0,0,1,.5.5A.51.51,0,0,1,46.64,12.78Z"
                />
                <path
                  class="cls-1"
                  d="M44.78,17.38H37.89c-.18,0-.32-.23-.32-.5s.14-.5.32-.5h6.89c.18,0,.32.22.32.5S45,17.38,44.78,17.38Z"
                />
                <path
                  class="cls-1"
                  d="M44.78,20H37.89c-.18,0-.32-.23-.32-.5s.14-.5.32-.5h6.89c.18,0,.32.22.32.5S45,20,44.78,20Z"
                />
                <path
                  class="cls-1"
                  d="M46.64,25.12H36.07a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H46.64a.5.5,0,0,1,.5.5A.51.51,0,0,1,46.64,25.12Z"
                />
                <path
                  class="cls-1"
                  d="M33.24,24.06H12.83a1.22,1.22,0,0,1-1.21-1.22V21.62a.5.5,0,0,1,.5-.5.51.51,0,0,1,.5.5v1.22a.21.21,0,0,0,.21.22H33.24a.5.5,0,0,1,.5.5A.5.5,0,0,1,33.24,24.06Z"
                />
                <path
                  class="cls-1"
                  d="M41.37,6.59a.5.5,0,0,1-.5-.5V1.92A.92.92,0,0,0,40,1H13.54a.92.92,0,0,0-.92.92.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5A1.92,1.92,0,0,1,13.54,0H40a1.92,1.92,0,0,1,1.92,1.92V6.09A.5.5,0,0,1,41.37,6.59Z"
                />
                <path
                  class="cls-1"
                  d="M31.78,16.49h-6A3.48,3.48,0,0,1,22.28,13a.5.5,0,1,1,1,0,2.48,2.48,0,0,0,2.47,2.47h6a.5.5,0,0,1,0,1Z"
                />
                <path
                  class="cls-1"
                  d="M31.29,18a.49.49,0,0,1-.35-.15.5.5,0,0,1,0-.71L32.07,16l-1.13-1.13a.5.5,0,0,1,.71-.71l1.48,1.49a.48.48,0,0,1,0,.7l-1.48,1.49A.51.51,0,0,1,31.29,18Z"
                />
                <path
                  class="cls-1"
                  d="M32.27,12.34a.5.5,0,0,1-.5-.5A2.48,2.48,0,0,0,29.3,9.36h-6a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h6a3.48,3.48,0,0,1,3.47,3.48A.5.5,0,0,1,32.27,12.34Z"
                />
                <path
                  class="cls-1"
                  d="M23.76,10.84a.47.47,0,0,1-.35-.15L21.93,9.22a.51.51,0,0,1,0-.71L23.41,7a.48.48,0,0,1,.7,0,.5.5,0,0,1,0,.71L23,8.86,24.11,10a.5.5,0,0,1,0,.71A.47.47,0,0,1,23.76,10.84Z"
                />
                <path
                  class="cls-1"
                  d="M3.38,14.65a.51.51,0,0,1-.5-.5A3.14,3.14,0,0,1,6,11a.5.5,0,0,1,.5.5A.5.5,0,0,1,6,12a2.14,2.14,0,0,0-2.14,2.14A.5.5,0,0,1,3.38,14.65Z"
                />
              </svg>
            </div>
            <div class="tile_content">
              <h2>CDN Cache</h2>
              <p>
                The content delivery network or CDN refreshes cache
                automatically every few hours. Manually refreshing cache can
                help edits to pubished items appear in production sooner.
              </p>
            </div>
          </a>
          <!-- Friendly URL Tile -->
          <a
            class="tile white hover-dp-blue"
            @click="handleFriendlyURLClick"
            @mouseover="isFriendlyURLHovered = true"
            @mouseleave="isFriendlyURLHovered = false"
          >
            <div class="tile_img">
              <svg
                class="furl-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 46 41"
              >
                <rect x="1" y="9" class="st0" width="44" height="31" />
                <line class="st0" x1="1" y1="15" x2="45" y2="15" />
                <line class="st0" x1="4" y1="12" x2="6" y2="12" />
                <line class="st0" x1="8" y1="12" x2="10" y2="12" />
                <line class="st0" x1="12" y1="12" x2="14" y2="12" />
                <line class="st0" x1="42" y1="5" x2="4" y2="5" />
                <line class="st0" x1="35" y1="1" x2="11" y2="1" />
              </svg>
            </div>
            <div class="tile_content">
              <h2>Friendly URLs</h2>
              <p>
                Ensures that state resources and information are accessible to
                every Ohioan
              </p>
            </div>
          </a>
          <!-- QR Code Tile -->
          <a 
            class="tile white hover-dp-blue"
            @click="handleQRCodeClick"
            @mouseover="isQRHovered = true"
            @mouseleave="isQRHovered = false"
          >
            <div class="tile_img">
              <svg
                class="qr-code-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 108.33 139.5"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_2-2" data-name="Layer 2">
                    <path
                      d="M63.83,139.4H9.1A9.11,9.11,0,0,1,0,130.3V9.1A9.11,9.11,0,0,1,9.1,0H63.83a9.11,9.11,0,0,1,9.1,9.1V85.7h-3V9.1A6.11,6.11,0,0,0,63.83,3H9.1A6.11,6.11,0,0,0,3,9.1V130.3a6.11,6.11,0,0,0,6.1,6.1H63.83A6,6,0,0,0,67.68,135l1.9,2.33A9.12,9.12,0,0,1,63.83,139.4Z"
                    />
                    <path d="M27,60.2H14V47.27H27ZM17,57.2H24V50.27H17Z" />
                    <path d="M27,92.25H14v-13H27Zm-10-3h7v-7H17Z" />
                    <path d="M59,60.2H45.9V47.27H59Zm-10.07-3H56V50.27H48.9Z" />
                    <rect x="45.96" y="85.25" width="3" height="7" />
                    <rect x="55.94" y="82.25" width="3" height="10" />
                    <rect x="38.94" y="79.25" width="3.01" height="13" />
                    <rect x="30.96" y="89.25" width="8.78" height="3" />
                    <rect x="30.96" y="73.27" width="3.01" height="8.98" />
                    <rect x="23.96" y="71.24" width="3.01" height="4.03" />
                    <rect x="33.58" y="79.25" width="6.9" height="3" />
                    <rect
                      x="22.47"
                      y="55.74"
                      width="3.01"
                      height="20.01"
                      transform="translate(-41.78 89.72) rotate(-90)"
                    />
                    <rect
                      x="10.56"
                      y="67.84"
                      width="9.82"
                      height="3"
                      transform="translate(-53.88 84.81) rotate(-90)"
                    />
                    <rect
                      x="27.31"
                      y="57.97"
                      width="10.34"
                      height="3"
                      transform="translate(-26.99 91.95) rotate(-90)"
                    />
                    <rect x="39" y="66.27" width="3.01" height="8.98" />
                    <rect x="41.62" y="72.25" width="9.35" height="3" />
                    <rect
                      x="35.44"
                      y="43.75"
                      width="3.01"
                      height="9.97"
                      transform="translate(85.69 11.78) rotate(90)"
                    />
                    <rect
                      x="35.76"
                      y="53.02"
                      width="9.35"
                      height="3"
                      transform="translate(94.96 14.09) rotate(90)"
                    />
                    <rect x="55.94" y="66.25" width="3" height="10.97" />
                    <rect x="47.97" y="79.25" width="3" height="3.05" />
                    <rect x="47.97" y="64.27" width="3" height="4" />
                    <rect
                      x="51.87"
                      y="85.75"
                      width="3"
                      height="10"
                      transform="translate(-37.38 144.12) rotate(-90)"
                    />
                    <path
                      d="M80.26,86.35a25.08,25.08,0,1,1-25.08,25.07A25.1,25.1,0,0,1,80.26,86.35m0-3a28.08,28.08,0,1,0,28.07,28.07A28.08,28.08,0,0,0,80.26,83.35Z"
                    />
                    <path
                      d="M93.27,100.94l1.65,1.66a.69.69,0,0,1,0,1L77.36,121.16a.72.72,0,0,1-1,0l-7.82-7.82a.71.71,0,0,1,0-1l1.66-1.66a.72.72,0,0,1,1,0l5.66,5.66,15.41-15.41A.72.72,0,0,1,93.27,100.94Z"
                    />
                    <rect x="1.83" y="14.35" width="68.7" height="3" />
                    <rect x="1.83" y="121.25" width="54.8" height="3" />
                  </g>
                </g>
              </svg>
            </div>
            <div class="tile_content">
              <h2>QR Codes</h2>
              <p>
                QR codes are a quick and easy way to direct users to your webpage. These codes can be used on flyers, newsletters, as well as print and social media campaigns.
              </p>
            </div>
          </a>
          <!--Remove this <a> tag once there is a new tile. Added this to fill in space beside QR tile -->
          <a class="tile" style="pointer-events: none"></a>
          <!--Remove this <a> tag once there is a new tile. Added this to fill in space beside QR tile -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import { AuthConfig } from "../auth/auth.config.js";
export default {
  name: "Home",
  data: function () {
    return {
      isFriendlyURLHovered: false,
      isCDNHovered: false,
      isQRHovered: false,
      //showQRCode: false
    };
  },
/*   created: function () {
    //Clear query params
    this.$router.push(this.$route.path);
    if (
      window.location.href.includes("localhost") ||
      window.location.href.includes("stg")
    ) {
      this.showQRCode = true
    }
  }, */
  methods: {
    handleToolsClick: function () {
      this.$router.push("/tools");
    },
    handleCDNCacheClick: function () {
      this.$router.push("/tools/cdn-cache-refresh");
    },
    handleFriendlyURLClick: function () {
      this.$router.push("/tools/friendly-urls");
    },
    handleQRCodeClick: function () {
      this.$router.push("/tools/qr-code");
    },
    handleEasterEggClick: function () {
      this.$router.push("/tools/friendly-urls");
    },
  },
};
</script>
<style scoped>
/*.main-section {
  min-height: 300px;
  background-color: #fbfcfe;
  padding: 40px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.main-section__header {
  margin: 0;
}*/
</style>
