<template>
  <footer>
    <div class="footer-container align-center justify-center">
      <div class="divider w1440"></div>
      <div class="footer-bar ">
        <div>
          <img src="../assets/images/iop-drk.png" width="216" alt="" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
