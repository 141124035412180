<template>
<main>
  
  <div class="">

<section class="relative gray-350 overflow-hidden " style="height:491px;"> 
  <div class="absolute " style=" top:-18%;"> 
    <img src="../assets/images/banner/home-header-img.png" alt="">
  </div>
  <div class="content-band mtb-0 max-w1200">
        <div class="container flex align-center " style="height: 490px; ">
         
          <div class="height-auto">
            <h1 style="font-size: 90px; line-height: 77px;">Ohio <br>Design System</h1>
              <p class="font-20 max-w500 mt-20">
              Creating a seamless user experience across state websites and
              applications, driven by the Ohio brand
            </p>
          </div>
          
        </div>
  </div>
</section>

<section>
    <div class="content-band max-w1200 pt-30">


            <div class="container flex">

                <div class="uxpin-logo">
                  <img src="../assets/images/uxpin-logo.png" alt="">
                </div>

                <div class="max-w400"> 
                  <div class="font-22 semibold">UXPIN DS2.0 UI Kit</div>
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos blanditiis praesentium voluptatum.
                  </p>
                </div>

                <div class="flex flex-center">
                  <a href="https://preview.uxpin.com/795fd758202e0842a944b656bf844668037a5b29" target="_blank">
                    <button class="secondary rounded-full hover-black">
                      <div class="btn-txt font-14 semibold">DS2 UI KIT</div>
                    </button>
                  </a>
                </div>

            </div>
      

        <div class="container flex mt-40" style="max-height: 465px;">

            <div class="container basis-30">
                <div class="card height-full max-w400 gray-300 pointer hover-white" @click="handleLinkClick('experience-pillars')">
                  <div class="card__img" style="height: 233px;"> 
                      <img class="img-cover" src="../assets/images/ps-card-img.png" alt="">
                  </div>
                  <div class="card__content ptb-40 plr-0">
                      <header>
                          <div class="card__title plr-30 font-36 relative">
                            <div class="red-line"></div>
                            Philosophy <br>&amp; Standards
                          </div>
                      </header>
                          <p class="plr-30">
                              The design philosophy, goals, and general principles of the Ohio Design System.
                          </p>
                  </div>
                </div>
            </div>

            <div class="container basis-60 gray-300">

                <div class="card__lrg-2col even-cols height-full">

                <div class="container max-w320 red-800 img-fc">
                    <img class="img-bottom " src="../assets/images/home-lrg-card_img.png" alt="">
                </div>
                
                <div class="card__lrg-2col-content">
                    <div class="container">
                        <header class="fo-h">
                            <div class="font-42">Foundations Overview</div>
                        </header>
                        <p class="fo-p">
                          Foundations are fundamental elements that provide structure 
                          and consistency to citizen digital experiences.
                        </p>

                        <div class="flex mt-30">
                          <a href="" target="_blank">
                            <button class="secondary rounded-full hover-black">
                              <div class="btn-txt font-14 semibold">Lorem ipsum</div>
                            </button>
                          </a>
                        </div>

                    </div>
                </div>
              </div>

            </div>
        </div>
        <!-- <div class="container white basis-40 flex align-center justify-center p-50 min-w300">
          <div class="max-w300">
            <h2 style="font-size: 35px;line-height: 40px;">
              <router-link
                to="/design-system/playground"
                :class="{ disabled: !enablePlayground }"
                >P</router-link
              >hilosophy &amp; Standards
            </h2>
            <p>
              The design philosophy, goals, and general principles of the Ohio
              Design System.
            </p>
            <nav>
              <ul class="philos-home-list">
                <li @click="handleLinkClick('experience-pillars')" role="link">
                  Experience Pillars
                </li>
                <li @click="handleLinkClick('experience-principles')" role="link">
                  Experience Principles
                </li>
                <li @click="handleLinkClick('accessibility-standards')" role="link">
                  Accessibility Standards
                </li>
                <li @click="handleLinkClick('terms-&-definitions')" role="link">
                  Terms &amp; Definitions
                </li>
              </ul>
            </nav>
          </div>
        </div>  -->
      </div>
    </section>

    <section>
      <div class="content-band even-cols gap-30 max-w1200">
        <a
          class="tile hover-white gray-250"
          aria-label="foundations"
          @click="handleFoundationsClick"
        >
          <div class="tile_img">
            <img
              src="../assets/images/tiles/foundations-icon-main.png"
              alt="Foundations icon"
            />
          </div>
          <div class="tile_content">
            <h2>Utility Library</h2>
            <p>
              The fundamental visual elements that provide structure and
              consistency to state digital experiences.
            </p>
          </div>
        </a>

        <a
          class="tile hover-white gray-250"
          aria-label="building blocks"
          @click="handleBuildingBlocksClick"
        >
          <div class="tile_img">
            <img
              src="../assets/images/tiles/blocks-icon-main.png"
              alt="Building Blocks icon"
            />
          </div>
          <div class="tile_content">
            <h2>Building Blocks</h2>
            <p>
              Ensures that state resources and information are accessible to
              every Ohioan
            </p>
          </div>
        </a>
      </div>
    </section>


  </div>
  </main>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  methods: {
    handleLinkClick: function(route) {
      this.$router.push(`/design-system/philosophy-standards/${route}`);
    },
    handleFoundationsClick: function() {
      this.$router.push("/design-system/foundations");
    },
    handleBuildingBlocksClick: function() {
      this.$router.push("/design-system/building-blocks");
    },
  },
  data: function() {
    return {
      enablePlayground: false,
    };
  },
  created: function() {
    if (
      window.location.href.includes("localhost") ||
      window.location.href.includes("stg")
    ) {
      this.enablePlayground = true;
    }
  },
};
</script>

