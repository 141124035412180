import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import DevPortal from "../views/DevPortal.vue";
// import ComponentsOverview from "../views/ComponentsOverview.vue";
// import BlocksOverview from "../views/BlocksOverview.vue";
// import ViewsOverview from "../views/ViewsOverview.vue";
// import PagesOverview from "../views/PagesOverview.vue";

Vue.use(VueRouter);

const routes = [
  //@TODO- Make a temporary home that handles pre-auth rendering
  {
    path: "/",
    name: "Ohio DevPortal",
    component: DevPortal,
    meta: {
      mainFooter: true,
    },
  },
  {
    path: "/index.html",
    name: "Ohio DevPortal",
    component: DevPortal,
    meta: {
      mainFooter: true,
    },
  },
  {
    path: "/design-system",
    name: "Home",
    component: Home,
    meta: {
      mainFooter: true,
    },
  },
  {
    path: "/design-system/philosophy-standards",
    name: "PhilosophyStandards",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "philosophy-standards" */ "../views/PhilosophyStandards"
      ),
    meta: {
      mainFooter: true,
    },
  },
  {
    path: "/design-system/foundations",
    name: "Foundations",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "foundations" */ "../views/Foundations.vue"),
    meta: {
      mainFooter: true,
    },
  },
  {
    path: "/design-system/building-blocks",
    name: "BuildingBlocks",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "building-blocks" */ "../views/BuildingBlocks.vue"
      ),
    meta: {
      mainFooter: true,
    },
  },
  {
    path: "/design-system/utilities",
    name: "Utilities",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "utilities" */ "../views/Utilities.vue"),
    meta: {
      mainFooter: true,
    },
  },
  {
    path: "/design-system/utilities/flexbox",
    name: "Flexbox",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "flexbox" */ "../components/_utilities/Flexbox.vue"
      ),
  },
  {
    path: "/design-system/utilities/hover-&-focus",
    name: "HoverFocus",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "hover-focus" */ "../components/_utilities/HoverFocus.vue"
      ),
  },
  {
    path: "/design-system/utilities/size-&-spacing",
    name: "SizeSpacing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "size-spacing" */ "../components/_utilities/SizeSpacing.vue"
      ),
  },
  {
    path: "/design-system/utilities/colors",
    name: "Colors",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "colors" */ "../components/_utilities/Colors.vue"
      ),
  },
  {
    path: "/design-system/utilities/fonts",
    name: "fonts",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "fonts" */ "../components/_utilities/Fonts.vue"
      ),
  },
  {
    path: "/design-system/utilities/images",
    name: "Images",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "images" */ "../components/_utilities/Images.vue"
      ),
  },
  {
    path: "/design-system/building-blocks/components",
    name: "Components",
    component: () =>
      import(/* webpackChunkName: "components"*/ "../views/ComponentsOverview"),
  },
  {
    path: "/design-system/building-blocks/blocks",
    name: "Blocks",
    component: () =>
      import(/* webpackChunkName: "blocks"*/ "../views/BlocksOverview"),
  },
  {
    path: "/design-system/building-blocks/views",
    name: "Views",
    component: () =>
      import(/* webpackChunkName: "views"*/ "../views/ViewsOverview"),
  },
  {
    path: "/design-system/building-blocks/pages",
    name: "Pages",
    component: () =>
      import(/* webpackChunkName: "pages"*/ "../views/PagesOverview"),
  },
  {
    path: "/design-system/building-blocks/components/accordions",
    name: "Accordions",
    component: () =>
      import(
        /*webpackChunkName: "accordions"*/ "../components/_components/Accordions/Accordions"
      ),
  },
  {
    path: "/design-system/building-blocks/components/alerts",
    name: "Alerts",
    component: () =>
      import(/*webpackChunkName: "alerts"*/ "../components/_components/Alerts/Alert"),
  },
  {
    path: "/design-system/building-blocks/components/buttons",
    name: "Button",
    component: () =>
      import(/*webpackChunkName: "button"*/ "../components/_components/Button/Button"),
  },
  {
    path: "/design-system/building-blocks/components/card",
    name: "Card",
    component: () =>
      import(/*webpackChunkName: "card"*/ "../components/_components/Cards/Card"),
  },
  {
    path: "/design-system/building-blocks/components/carousel",
    name: "Carousel",
    component: () =>
      import(
        /*webpackChunkName: "carousel"*/ "../components/_components/Carousel"
      ),
  },
  {
    path: "/design-system/building-blocks/components/dividers",
    name: "Dividers",
    component: () =>
      import(
        /*webpackChunkName: "diverders"*/ "../components/_components/Divider/Dividers"
      ),
  },
  {
    path: "/design-system/building-blocks/components/list",
    name: "List",
    component: () =>
      import(
        /*webpackChunkName: "diverders"*/ "../components/_components/List/List"
      ),
  },
  {
    path: "/design-system/building-blocks/components/modal",
    name: "Modal",
    component: () =>
      import(
        /*webpackChunkName: "modal"*/ "../components/_components/Modal/Modal.vue"
      ),
  },
  {
    path: "/design-system/building-blocks/components/forms",
    name: "Forms",
    component: () =>
      import(
        /*webpackChunkName: "carousel"*/ "../components/_components/Forms"
      ),
  },
  {
    path: "/design-system/building-blocks/components/input",
    name: "Input",
    component: () =>
      import(/*webpackChunkName: "input"*/ "../components/_components/Input"),
  },
  {
    path: "/design-system/building-blocks/components/checkbox",
    name: "Checkbox",
    component: () =>
      import(
        /*webpackChunkName: "checkbox"*/ "../components/_components/Checkbox"
      ),
  },
  {
    path: "/design-system/building-blocks/components/radio-button",
    name: "RadioButton",
    component: () =>
      import(
        /*webpackChunkName: "radio-button"*/ "../components/_components/RadioButton"
      ),
  },
  {
    path: "/design-system/building-blocks/components/stepper",
    name: "Stepper",
    component: () =>
      import(
        /*webpackChunkName: "diverders"*/ "../components/_components/Stepper/Stepper"
      ),
  },
  {
    path: "/design-system/building-blocks/components/date-picker",
    name: "DatePicker",
    component: () =>
      import(
        /*webpackChunkName: "date-picker"*/ "../components/_components/DatePicker"
      ),
  },
  {
    path: "/design-system/building-blocks/components/dropdown",
    name: "Dropdown",
    component: () =>
      import(
        /*webpackChunkName: "dropdown"*/ "../components/_components/Dropdown"
      ),
  },
  {
    path: "/design-system/building-blocks/components/textarea",
    name: "TextArea",
    component: () =>
      import(
        /*webpackChunkName: "text-area"*/ "../components/_components/TextArea"
      ),
  },
  {
    path: "/design-system/building-blocks/components/input",
    name: "TextInput",
    component: () =>
      import(
        /*webpackChunkName: "text-input"*/ "../components/_components/Input"
      ),
  },
  {
    path: "/design-system/building-blocks/blocks/modal",
    name: "Modal",
    component: () =>
      import(/*webpackChunkName: "modal"*/ "../components/_blocks/Modal"),
  },
  {
    path: "/design-system/building-blocks/components/profile",
    name: "Profile",
    component: () =>
      import(
        /*webpackChunkName: "profile"*/ "../components/_components/Profile"
      ),
  },
  {
    path: "/design-system/building-blocks/components/slider",
    name: "Slider",
    component: () =>
      import(/*webpackChunkName: "slider"*/ "../components/_components/Slider"),
  },
  {
    path: "/design-system/building-blocks/components/spinners",
    name: "Spinner",
    component: () =>
      import(
        /*webpackChunkName: "spinner"*/ "../components/_components/Spinner"
      ),
  },
  {
    path: "/design-system/building-blocks/components/tabs",
    name: "Tabs",
    component: () =>
      import(/*webpackChunkName: "tabs"*/ "../components/_components/Tabs/Tabs"),
  },
  {
    path: "/design-system/building-blocks/components/tiles",
    name: "Tiles",
    component: () =>
      import(/*webpackChunkName: "tiles"*/ "../components/_components/Tiles/Tiles"),
  },
  {
    path: "/design-system/building-blocks/blocks/primary-header",
    name: "PrimaryHeader",
    component: () =>
      import(/*webpackChunkName: "blocks"*/ "../components/_blocks/PrimaryHeader/PrimaryHeader"),
  },
  {
    path: "/design-system/building-blocks/blocks/header-component",
    name: "HeaderComponent",
    component: () =>
      import(/*webpackChunkName: "blocks-demo"*/ "../components/_blocks/PrimaryHeader/HeaderComponent"),
  },
  {
    path: "/design-system/building-blocks/blocks/footer",
    name: "Footer",
    component: () =>
      import(/*webpackChunkName: "blocks"*/ "../components/_blocks/Footer/Footer"),
  },
  {
    path: "/design-system/building-blocks/blocks/footer-component",
    name: "FooterComponent",
    component: () =>
      import(/*webpackChunkName: "blocks-demo"*/ "../components/_blocks/Footer/FooterComponent"),
  },
  {
    path: "/design-system/building-blocks/views/drawers",
    name: "Drawers",
    component: () =>
      import(/*webpackChunkName: "drawers"*/ "../components/_views/Drawers"),
  },
  {
    path: "/design-system/building-blocks/views/stepper",
    name: "Stepper",
    component: () =>
      import(/*webpackChunkName: "stepper"*/ "../components/_views/Stepper"),
  },
  {
    path: "/design-system/building-blocks/pages/search-results",
    name: "SearchResults",
    component: () =>
      import(
        /*webpackChunkName: "search-resuls"*/ "../components/_pages/SearchResults"
      ),
  },
  {
    path: "/design-system/building-blocks/pages/401-error",
    name: "UnathorizedError",
    component: () =>
      import(
        /*webpackChunkName: "unathorizeded-error"*/ "../components/_pages/UnathorizedError"
      ),
  },
  {
    path: "/design-system/building-blocks/pages/403-error",
    name: "ForbiddenError",
    component: () =>
      import(
        /*webpackChunkName: "forbidden-error"*/ "../components/_pages/ForbiddenError"
      ),
  },
  {
    path: "/design-system/building-blocks/pages/404-error",
    name: "NotFoundError",
    component: () =>
      import(
        /*webpackChunkName: "notfound-error"*/ "../components/_pages/NotFoundError"
      ),
  },
  {
    path: "/design-system/building-blocks/pages/500-error",
    name: "ServerError",
    component: () =>
      import(
        /*webpackChunkName: "server-error"*/ "../components/_pages/ServerError"
      ),
  },
  {
    path: "/design-system/building-blocks/pages/maintenance",
    name: "Maintenance",
    component: () =>
      import(
        /*webpackChunkName: "maintenance"*/ "../components/_pages/Maintenance"
      ),
  },
  {
    path: "/design-system/philosophy-standards/accessibility-standards",
    name: "AccessibilityStandards",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "accessibility-standards" */ "../components/_philosophy-standards/AccessibilityStandards"
      ),
  },
  {
    path: "/design-system/philosophy-standards/experience-pillars",
    name: "ExperiencePillars",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "experience-pillars" */ "../components/_philosophy-standards/ExperiencePillars"
      ),
  },
  {
    path: "/design-system/philosophy-standards/experience-principles",
    name: "ExperiencePrinciples",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "experience-principles" */ "../components/_philosophy-standards/ExperiencePrinciples"
      ),
  },
  {
    path: "/design-system/philosophy-standards/terms-&-definitions",
    name: "TermsDefinitions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "terms-definitions" */ "../components/_philosophy-standards/TermsDefinitions"
      ),
  },
  {
    path: "/design-system/foundations/photography",
    name: "Photography",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "photography" */ "../components/_foundations/Photography"
      ),
  },
  {
    path: "/design-system/foundations/illustration",
    name: "Illustration",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "illustration" */ "../components/_foundations/Illustration"
      ),
  },
  {
    path: "/design-system/foundations/icons",
    name: "Icons",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "icons" */ "../components/_foundations/Icons"
      ),
  },
  {
    path: "/design-system/foundations/typography",
    name: "Typography",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "typography" */ "../components/_foundations/Typography"
      ),
  },
  {
    path: "/design-system/foundations/color",
    name: "Color",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "color" */ "../components/_foundations/Color"
      ),
  },
  {
    path: "/design-system/foundations/layout-&-spacing",
    name: "LayoutSpacing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "layout-spacing" */ "../components/_foundations/LayoutSpacing"
      ),
  },
  {
    path: "/design-system/foundations/flexbox-utilities",
    name: "LayoutSpacing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "layout-spacing" */ "../components/_foundations/FlexboxUtilities"
      ),
  },
  {
    path: "/design-system/foundations/layout-regions",
    name: "LayoutSpacing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "layout-spacing" */ "../components/_foundations/LayoutRegions"
      ),
  },
  {
    path: "/design-system/playground",
    name: "Playground",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Playground" */ "../components/_pages/Playground.vue"
      ),
  },
  {
    path: "/tools",
    name: "Tools",
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/Tools.vue"),
  },
  {
    path: "/tools/cdn-cache-refresh",
    name: "cdn-cache",
    component: () =>
      import(/*webpackChunkName: "cdn-cache"*/ "../components/SectionCards"),
  },
  {
    path: "/tools/friendly-urls",
    name: "friendly-url",
    component: () =>
      import(/*webpackChunkName: "friendly-url"*/ "../components/FriendlyUrl"),
  },
  {
    path: "/tools/qr-code",
    name: "qr-code",
    component: () =>
      import(/*webpackChunkName: "qr-code"*/ "../components/_devportal/QrCode/QRCode"),
  },
  {
    path: "/guides",
    name: "Guides",
    component: () =>
      import(/* webpackChunkName: "guides" */ "../views/Guides.vue"),
  },
  {
    path: "/guides/iop-web-forms",
    name: "WebForms",
    component: () =>
      import(
        /* webpackChunkName: "webForms" */ "../components/IOPWebForms.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // const showDPNav = localStorage.getItem("showDPNav");

  // if (showDPNav) {
  //   console.log("showDPNav exists");
  // } else {
  //   localStorage.setItem("showDPNav", "true");
  // }

  if (to.path.includes("/design-system")) {
    document.title = "Ohio Design System";
    localStorage.setItem("showDPNav", "false");
  } else {
    document.title = "Ohio Dev Portal";
    localStorage.setItem("showDPNav", "true");
  }
  next();
});

// router.afterEach((to, from) => {
//   console.log("hi from navigation guard");
//   console.log(to);
//   console.log(from);
// });

export default router;
