import { AuthConfig } from "./auth.config";

/* global KEYUTIL, KJUR, jQuery */

/* eslint-disable no-unused-vars */
var key_id;
var keys;
var key_index;
/* eslint-disable no-unused-vars */

var urlParams = new URLSearchParams(window.location.search);
var tokens;
var authConfig = AuthConfig.getCurrentAuthConfig();

async function verifyToken(token) {
  //Get the kid (key id)
  var tokenHeader = parseJWTHeader(token);
  key_id = tokenHeader.kid;

  //search for the kid key id in the Cognito Keys
  const key = keys.find((key) => key.kid === key_id);
  if (key === undefined) {
    return "Public key not found in Cognito jwks.json";
  }

  //verify token has not expired
  var tokenPayload = parseJWTPayload(token);
  sessionStorage.setItem("tokenExpiresIn", tokenPayload.exp);
  if (Date.now() >= tokenPayload.exp * 1000) {
    return "Token expired";
  }

  //verify app_client_id
  var n = tokenPayload.aud.localeCompare(authConfig.appClientId);
  if (n != 0) {
    return "Token was not issued for this audience";
  }

  //verify JWT Signature
  var keyObj = KEYUTIL.getKey(key);
  var isValid = KJUR.jws.JWS.verifyJWT(token, keyObj, { alg: ["RS256"] });
  if (!isValid) {
    return "Signature verification failed";
  }

  return "verified";
}

//Convert Payload from Base64-URL to JSON
const decodePayload = (payload) => {
  const cleanedPayload = payload.replace(/-/g, "+").replace(/_/g, "/");
  const decodedPayload = atob(cleanedPayload);
  const uriEncodedPayload = Array.from(decodedPayload).reduce((acc, char) => {
    const uriEncodedChar = ("00" + char.charCodeAt(0).toString(16)).slice(-2);
    return `${acc}%${uriEncodedChar}`;
  }, "");
  const jsonPayload = decodeURIComponent(uriEncodedPayload);

  return JSON.parse(jsonPayload);
};

//Parse JWT Payload
const parseJWTPayload = (token) => {
  const [header, payload, signature] = token.split(".");
  const jsonPayload = decodePayload(payload);

  return jsonPayload;
};

//Parse JWT Header
const parseJWTHeader = (token) => {
  const [header, payload, signature] = token.split(".");
  const jsonHeader = decodePayload(header);

  return jsonHeader;
};

//Generate a Random String
const getRandomString = () => {
  const randomItems = new Uint32Array(28);
  crypto.getRandomValues(randomItems);
  const binaryStringItems = randomItems.map(
    (dec) => `0${dec.toString(16).substr(-2)}`
  );
  return binaryStringItems.reduce((acc, item) => `${acc}${item}`, "");
};

//Encrypt a String with SHA256
const encryptStringWithSHA256 = async (str) => {
  const PROTOCOL = "SHA-256";
  const textEncoder = new TextEncoder();
  const encodedData = textEncoder.encode(str);
  return crypto.subtle.digest(PROTOCOL, encodedData);
};

//Convert Hash to Base64-URL
const hashToBase64url = (arrayBuffer) => {
  const items = new Uint8Array(arrayBuffer);
  const stringifiedArrayHash = items.reduce(
    (acc, i) => `${acc}${String.fromCharCode(i)}`,
    ""
  );
  const decodedHash = btoa(stringifiedArrayHash);

  const base64URL = decodedHash
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  return base64URL;
};

var loadScriptAsync = function(uri) {
  return new Promise((resolve, reject) => {
    var tag = document.createElement("script");
    tag.src = uri;
    tag.async = true;
    tag.onload = () => {
      resolve();
    };
    tag.onerror = () => {
      reject();
    };
    var firstScriptTag = document.head.appendChild(tag);
  });
};

// Get user token
var handleTokens = function(tokens) {
  var userToken = tokens.id_token;
  //var expiresIn = tokens.expires_in;
  sessionStorage.setItem("userIDtoken", userToken);
};

var clearBrowserStorage = function() {
  localStorage.clear();
  sessionStorage.clear();
};

var logoutAndReinitiateLogin = function() {
  clearBrowserStorage();
  window.location.href = "https://" + authConfig.domain + ".auth." + authConfig.region + ".amazoncognito.com/logout?client_id=" + authConfig.appClientId + "&logout_uri=" + authConfig.redirectURI;
};

// Main Function
export default async function main() {
  var code = urlParams.get("code");

  //If code not present then request code else request tokens
  if (!code || urlParams.get("state") != sessionStorage.getItem("pkce_state")) {
    //Clear the browser storage
    clearBrowserStorage();

    // Create random "state"
    var state = getRandomString();
    sessionStorage.setItem("pkce_state", state);

    // Create PKCE code verifier
    var code_verifier = getRandomString();
    sessionStorage.setItem("code_verifier", code_verifier);

    // Create code challenge
    var arrayHash = await encryptStringWithSHA256(code_verifier);
    var code_challenge = hashToBase64url(arrayHash);
    sessionStorage.setItem("code_challenge", code_challenge);

    // Redirtect user-agent to /authorize endpoint
    location.href = "https://" + authConfig.domain + ".auth." + authConfig.region + ".amazoncognito.com/oauth2/authorize?response_type=code&state=" + state +
          "&client_id=" + authConfig.appClientId + "&redirect_uri=" + authConfig.redirectURI + "&scope=openid&code_challenge_method=S256&code_challenge=" + code_challenge;
    
    return false;
  } else {
    //Validate user has access to this portal
    sessionStorage.setItem("ohidUrl", authConfig.wcmComponentToCheckAccessDomainName);

    // Fetch OAuth2 tokens from Cognito
    code_verifier = sessionStorage.getItem("code_verifier");

    jQuery.when(
      jQuery.getJSON("https://cognito-idp." + authConfig.region + ".amazonaws.com/" + authConfig.userPoolId + "/.well-known/jwks.json").done(function(data) {
        keys = data["keys"]
      }),
      jQuery.ajax({
        type: "POST",
        url: "https://" + authConfig.domain + ".auth." + authConfig.region + ".amazoncognito.com/oauth2/token?grant_type=authorization_code&client_id="
              + authConfig.appClientId + "&code_verifier=" + code_verifier + "&redirect_uri=" + authConfig.redirectURI + "&code=" + code,
        contentType: 'application/x-www-form-urlencoded',
        dataType: 'json'
      }).done(function(data){
        tokens = data;
        // pass tokens for session storage
        handleTokens(tokens);
      })
    ).done(function() {
      var idVerified = verifyToken(tokens.id_token);
      Promise.resolve(idVerified).then(function(value) {
        if (value.localeCompare("verified")) {
          console.log("Invalid ID Token - " + value);
          return;
        }
      });

      //Retrieve the userInfo
      jQuery.ajax({
        type: "POST",
        url: "https://" + authConfig.domain + ".auth." + authConfig.region + ".amazoncognito.com/oauth2/userInfo",
        headers: {
          "authorization": "Bearer " + tokens.access_token,
        },
        dataType: 'json'
      }).done(function() {
        //When session is valid, load the WCM permissions
        loadScriptAsync(AuthConfig.wcmComponentURLForAccessCheck()).then(AuthConfig.handleResponseForAccessCheck, logoutAndReinitiateLogin);
      }).fail(function() {
        console.log("Error retrieving userInfo");
      });
    }).fail(function() {
      console.log("Error retrieving auth token");
    });

    return true;
  }
}




