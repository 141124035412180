
/**
 * Add all application client settings for Cognito in this file
 */

var AuthConfig = {
  production: {
    hostName: "devportal.iop.ohio.gov",
    domain: "devportal-iop",
    region: "us-east-1",
    appClientId: "6shcuf0fqdvoqp2augp9pb1mlh",
    userPoolId: "us-east-1_JiHzITQnC",
    redirectURI: "https://devportal.iop.ohio.gov/index.html",
    wcmComponentToCheckAccessDomainName: "ohid.ohio.gov",
    apiGateWay: "https://apigw-prd.odx.ohio.gov"
  },
  staging: {
    hostName: "devportal-stg.iop.ohio.gov",
    domain: "devportal-stg-iop",
    region: "us-east-1",
    appClientId: "22bku0fcndnuhr3u1r6ciur690",
    userPoolId: "us-east-1_O23yUiRK2",
    redirectURI: "https://devportal-stg.iop.ohio.gov/index.html",
    wcmComponentToCheckAccessDomainName: "ohid-stg.ohio.gov",
    apiGateWay: "https://apigw-stg.odx.ohio.gov"
  },
  local: {
    hostName: "devportal-local.iop.ohio.gov",
    domain: "devportal-stg-iop",
    region: "us-east-1",
    appClientId: "1tcr8svq9ucrsqrii5i7u1hb7a",
    userPoolId: "us-east-1_O23yUiRK2",
    redirectURI: "https://devportal-local.iop.ohio.gov:8080/index.html",
    wcmComponentToCheckAccessDomainName: "ohid-stg.ohio.gov"
  },
  getCurrentAuthConfig: function() {
    var hostName = document.location.hostname;
    if (hostName === this.production.hostName) {
      return this.production;
    } else if (hostName === this.staging.hostName) {
      return this.staging;
    } else if (hostName === this.local.hostName) {
      return this.local;
    }
  },
  handleResponseForAccessCheck: function() {
    if (!window.OHID || !window.OHID.doesUserHaveDevPortalAccess) {
      //Redirect to the 403 page
      window.location.href = "/forbidden.html";
    }

    var siteAccessData = JSON.stringify(window.OHID.userSiteAccess);
    sessionStorage.setItem("userRoles", siteAccessData);
    return siteAccessData;
  },
  wcmComponentURLForAccessCheck: function() {
    var stgURL = "https://" + this.getCurrentAuthConfig().wcmComponentToCheckAccessDomainName +
      "/wps/wcm/myconnect/gov/ohio+content+english/ohid?srv=cmpnt&source=library&cmpntname=ohio+design/global/app-access-check/check-dev-portal-access-roles&subtype=javascript";
    var prodURL = "https://" + this.getCurrentAuthConfig().wcmComponentToCheckAccessDomainName +
      "/wps/wcm/myconnect/gov/ohio+content+english/ohid?srv=cmpnt&source=library&cmpntname=ohio+design/global/app-access-check/check-dev-portal-access-roles&subtype=javascript";

    return (this.getCurrentAuthConfig().wcmComponentToCheckAccessDomainName === this.production.wcmComponentToCheckAccessDomainName)? prodURL : stgURL;
  }
};

export { AuthConfig };


