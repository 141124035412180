<template>
  <div
    id="app"
    class="page-container gray-50"
    :class="{ 'dev-portal': showDevPortalNav }"
  >
    <header>
      <nav aria-label="Primary navigation">
        <div
          id="navContainer"
          class="nav_container"
          v-if="!showDevPortalNav"
        >
          <div class="nav-bar width-full" style="min-height: 95px;">
            <!-- Logo -->
            <div
              id="logo"
              class="container logo basis-10 "
              @click="handleLogoClick"
            >
            <div class="overflow-hidden w100 h35">
              <img
                class="pointer h35 img-cover img-left"
                src="./assets/images/SVG/logo.svg"
                title="Welcome to the Ohio Design System Portal"
                alt="Return home"
                tabindex="0"
              />
            </div>
            </div>

            <!-- Main Nav Links -->
            <div id="menu" role="menubar" class="container basis-70">
              <span class="ds-version-txt">DS2.0</span>
              <div id="navLinks" class="nav-links links-red dot justify-end font-16 pr-30" aria-label="main menu">
                
                <div class="nav-link-item">
                  <a :class="{
                      active: currentRoute.includes('/philosophy-standards'),
                    }"
                  >
                    <router-link
                      to="/design-system/philosophy-standards"
                      class="router-link navBtn"
                      tabindex="0"
                    >
                      Philosophy &amp; Standards
                    </router-link>
                  </a>
                </div>

                <div class="nav-link-item">
                  <a
                    :class="{ active: currentRoute.includes('/foundations') }"
                    tabindex="0"
                  >
                    <router-link
                      to="/design-system/foundations"
                      class="router-link navBtn"
                      tabindex="0"
                      >Foundations</router-link
                    >
                  </a>
                </div>

                <div class="nav-link-item">
                  <a
                    :class="{ active: currentRoute.includes('/utilities') }"
                    tabindex="0"
                  >
                    <router-link
                      to="/design-system/utilities"
                      class="router-link navBtn"
                      tabindex="0"
                      >Utility Library</router-link
                    >
                  </a>
                </div>

                <div class="nav-link-item">
                  <a
                    :class="{
                      active: currentRoute.includes('/building-blocks'),
                    }"
                    tabindex="0"
                  >
                    <router-link
                      to="/design-system/building-blocks"
                      class="router-link navBtn"
                      tabindex="0"
                      >Building Blocks</router-link
                    >
                  </a>
                </div>

                <!-- <div class="nav-link-item">
                                <a  :class="{ active: currentRoute.includes('/how-to') }"
                                    @click="handleNavClick('How To')"
                                    tabindex="0">
                                    <router-link to="/building-blocks" class="router-link navBtn" tabindex="-1">How To</router-link>
                                </a>
                            </div> -->
              </div>
            </div>

            <div id="utilities" class="container nav-utility-links">
              <button
                class="rounded-8 flex-row-reverse red-100" @click="redirectDevPortal"
              >
                <div class="btn-txt semibold font-15 font-red">Developer Portal</div>
                <div class="icon font-red font-20">
                  <i class="fad fa-file-code"></i>
                </div>
              </button>
            </div>
          </div>
        </div>

        <!-- DEV PORTAL -->
        <div v-else>
          <div class="nav-container dp-drk-blue">
            <div class="nav-bar width-full min-h80 semibold">
              <div class="container logo basis-10 align-self-center">
                <img
                  class="pointer h35"
                  src="./assets/dev-portal_logo.png"
                  alt="design system logo"
                />
              </div>
              <!-- Main Nav Links -->
              <div id="menu" role="menubar" class="container basis-70">
                <div
                  id="navLinks"
                  class="nav-links links-red dot justify-end font-16"
                  aria-label="main menu"
                >
                  <div class="nav-link-item">
                    <a
                      :class="{
                        active: currentRoute.includes('/philosophy-standards'),
                      }"
                    >
                      <router-link
                        to="/tools"
                        class="router-link navBtn font-white"
                        tabindex="0"
                      >
                        Tools
                      </router-link>
                    </a>
                  </div>

                  <div class="nav-link-item" v-show="hideDevGuides">
                    <a
                      :class="{ active: currentRoute.includes('/foundations') }"
                      tabindex="0"
                    >
                      <router-link
                        to="/guides"
                        class="router-link navBtn font-white"
                        tabindex="0"
                        >Developer Guides</router-link
                      >
                    </a>
                  </div>
                </div>
              </div>

              <div id="utilities" class="container nav-utility-links">
                <button
                  class="secondary rounded-8 flex-row-reverse border-gray-400 hover-border-transparent hover-red-100 hover-font-red"
                  @click="redirectDesignSys"
                >
                  <div class="btn-txt semibold font-15 font-white">
                    Design System
                  </div>
                  <div class="icon font-red font-20">
                    <i class="fad fa-file-code"></i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
    <router-view />

    <Footer v-if="$route.meta.mainFooter === true" />
  </div>
</template>
<script>
import Footer from "./components/Footer";
// import Toggle from "./components/Toggle";
// import axios from "axios";
// @TODO - FIX THE DEV PORTAL HEADER BOOLEAN
export default {
  data: function() {
    return {
      // TODO: If you refresh the page while on DS, the nav for DP shows
      showDevPortalNav:
        localStorage.getItem("showDPNav") === "true" ? true : false,
      currentRoute: "",
      showGuidesLink: false,
      hideDevGuides: false,
      // showDevPortalToggle: false,
    };
  },
  components: {
    // Toggle,
    Footer,
  },
  watch: {
    $route(to) {
      this.currentRoute = to.path;
    },
  },
  methods: {
    handleLogoClick: function() {
      if (this.currentRoute != "/design-system") {
        if (this.showDevPortalNav) {
          this.$router.push("/");
        } else {
          this.$router.push("/design-system");
        }
      }
    },
    redirectDesignSys: function() {
      this.showDevPortalNav = false;
      this.$router.push("/design-system");
    },
    redirectDevPortal: function() {
      this.showDevPortalNav = true;
      this.$router.push("/");
    },
    // handleNavClick: function(route) {
    //   if (!this.currentRoute.includes(route)) {
    //     this.$router.push(`/design-system/${route}`);
    //   }
    // },
  },
  created: function() {
    if (
      window.location.href.includes("localhost") ||
      window.location.href.includes("stg")
    ) {
      this.showGuidesLink = true;
      this.hideDevGuides = true;
    }
    // document.title = "Ohio Design System";
    // if (window.location.href.includes("dev-portal")) {
    //   this.showDevPortalNav = true;
    //   document.title = "Ohio Dev Portal";
    // }
    console.log(this.$router.currentRoute.path);
  },
  // mounted: function() {
  //   console.log("this is the value of showDevPortalNav", this.showDevPortalNav);
  // },
  // mounted: function() {
  //   if (window.location.href.includes("dev-portal")) {
  //     this.showDevPortalNav = true;
  //     document.title = "Ohio Dev Portal";
  //   } else {
  //     document.title = "Ohio Design System";
  //   }
  // },
  // mounted: function() {
  //   console.log(this.$router.currentRoute.path);
  //   if (this.$router.currentRoute.path.includes("/dev-portal")) {
  //     console.log("im in dev portal");
  //     document.title = "Ohio Dev Portal";
  //   }
  // },
};
</script>
<style>
/* .nav-container {
  background-color: black;
}
.dev-portal ul.navbar-menu li a {
  color: white;
}
.button--design-sys {
  border: 1px solid white;
  color: white;
}
a.tile:hover .tile_content {
  color: black;
}
a.tile.tile--dp:hover .tile_content {
  color: white;
  transition: all 0s ease-in-out;
}
.dev-portal a.tile:hover .tile_content {
  color: white;
} */
</style>
