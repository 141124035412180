import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/ds-core.css"
import "./assets/css/ds-main.css";
import "./assets/css/dp-main.css";

import main from "./auth";

Vue.config.productionTip = false;

main().then(function(isAuthenticated) {
  if (isAuthenticated) {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});

window.addEventListener('DOMContentLoaded', () => {

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const id = entry.target.getAttribute('id');
      if (entry.intersectionRatio > 0) {
        document.querySelector(`nav li a[href="#${id}"]`).parentElement.classList.add('active');
        alert('asdfsdaf');
      } else {
        document.querySelector(`nav li a[href="#${id}"]`).parentElement.classList.remove('active');
      }
    });
  });

  // Track all sections that have an `id` applied
  document.querySelectorAll('section[id]').forEach((section) => {
    observer.observe(section);
  });
});

console.log(Vue.version);

/* new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app"); */
